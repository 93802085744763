<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <div class="navigation-logo">
      <v-img :src="require('@/assets/logo.svg')" />
    </div>

    <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h3">
        <strong class="mr-1 font-weight-black">Mairie</strong>

        <span class="accent--text">.MC</span>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  export default {
    name: 'DefaultDrawerHeader',

    computed: {},
  }
</script>

<style lang="scss">
.navigation-logo {
  width: 30px;
  margin-right: 10px;
}
</style>
